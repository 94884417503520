<template>
  <div>
        <el-card >
          <el-table :data="drayBillDetailList" >
            <el-table-column prop="id" :label="$t('msg.track.id')" align="center"></el-table-column>
            <el-table-column prop="bookId" :label="$t('msg.track.bookId')" align="center"></el-table-column>
            <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" align="center"></el-table-column>
            <el-table-column prop="item" :label="$t('msg.rate.item')" align="center"></el-table-column>
            <el-table-column prop="chargeType" :label="$t('msg.account.chargeType')" align="center"></el-table-column>
            <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center"></el-table-column>
            <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
              <template #default="scope">
                <span >$ {{scope.row.rate}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="amount" :label="$t('msg.account.amount')" align="center">
              <template #default="scope">
                <span >$ {{scope.row.amount}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="editor" :label="$t('msg.rate.csopName')" align="center"></el-table-column>
            <el-table-column prop="remark" :label="$t('msg.rate.remark')" align="center"></el-table-column>
          </el-table>
        </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'

import axios from 'axios'
export default defineComponent({
  name: 'drayBillDetail',
  props: {
    myParam: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const drayBillDetailList = ref()
    onMounted(() => {
      const param = props.myParam
      const reqURL = process.env.VUE_APP_BASE + '/getMyDrayBillDetail'
      axios.post(reqURL, param).then((response) => {
        drayBillDetailList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    return { drayBillDetailList, props }
  }
})
</script>
